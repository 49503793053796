import BaseBean from "@/utils/BaseBean";

export interface IBuildPageListDataObj {
    utilInst:BuildPageListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class BuildPageListUtil extends BaseBean{
    public dataObj:IBuildPageListDataObj

    constructor(proxy:any,dataObj:IBuildPageListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}